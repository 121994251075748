<template>
    <a-card>
        <a-page-header
            :title="`${id == 0 ? '新建广告主' : '广告主详情'}`"
        />
        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="广告主类型" prop='advertiserType'>
                <a-radio-group v-model="form.advertiserType" @change="onChangeType" v-if="id == 0">
                    <a-radio :value="1">汽车经销商</a-radio>
                    <a-radio :value="2">汽车品牌</a-radio>
                </a-radio-group>
                <span v-else>{{ form.advertiserType == 1 ? '汽车经销商' : form.advertiserType == 2 ? '汽车品牌' : '-' }}</span>
            </a-form-model-item>
            <a-form-model-item label="关联经销商" prop='externalIdentityId' v-if="form.advertiserType == 1">
                <a-select
                    v-model="form.externalIdentityId"
                    placeholder="请输入"
                    allow-clear
                    show-search
                    style="width: 100%;"
                    :filter-option="false"
                    @search="onSearchDealer"
                    @change="onChangeDealer"
                >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option
                        v-for="item in dealerList"
                        :key="item.id"
                    >
                        {{ item.name }} {{item.store_code ? `（${item.store_code}）` : ''}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联品牌" prop='externalIdentityId' v-if="form.advertiserType == 2">
                <a-select
                    v-model="form.externalIdentityId"
                    placeholder="请输入"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                        :name="item.principal_name"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="品牌" prop='brandManufactorList'>
                <span v-for="(item, index) in form.brandManufactorList">{{index > 0 ? '，' : ''}}{{ item.brand_name }}</span>
            </a-form-model-item>
            <a-form-model-item label="广告主名称" prop='advertiserName'>
                <a-input
                    v-model="form.advertiserName"
                    placeholder="填写广告主名称"
                />
            </a-form-model-item>
            <a-form-model-item label="广告主公司名" prop='advertiserCompany'>
                <a-input
                    v-model="form.advertiserCompany"
                    placeholder="填写营业执照的公司名，公司名错误将影响授权"
                />
            </a-form-model-item>
            <a-form-model-item label="投手" prop="operatorId">
                <a-select
                    v-model="form.operatorId"
                    placeholder="请选择"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                >
                    <a-select-option
                        v-for="item in pitcherList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        this.onSearchDealer = _.debounce(this.onSearchDealer, 1000)
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                advertiserType: 1,
                externalIdentityId: undefined,
                brandManufactorList: [],
                advertiserName: '',
                advertiserCompany: '',
                operatorId: undefined,
            },
            rules: {
                advertiserType: [
                    { required: true, message: '请选择广告主类型', trigger: 'change' }
                ],
                externalIdentityId: [
                    { required: true, message: '请选择关键经销商', trigger: 'change' }
                ],
                brandManufactorList: [
                    { type: 'array', required: true, message: '请选择品牌', trigger: 'change' }
                ],
                advertiserName: [
                    { required: true, message: '请输入广告主名称', trigger: 'change' }
                ],
                advertiserCompany: [
                    { required: true, message: '请输入广告主公司名', trigger: 'change' }
                ],
                operatorId: [
                    { required: true, message: '请选择投手', trigger: 'change' }
                ]
            },
            fetching: false,
            dealerList: [],
            principalList: [],
            pitcherList: [],
            isSubmit: false,
        }
    },
    created() {
        this.getPrincipalList()
        this.getUsersList()

        if(this.id != 0) {
            this.getAccountDetail()
        } else {
            this.onSearchDealer()
        }
    },
    methods: {
        getUsersList() {
            this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
                if(res.code == 200) {
                    this.pitcherList = res.data.list
                } else {
                    this.$message.error(`获取投手列表失败，${res.message}`)
                }
            })
        },
        getAccountDetail() {
            this.$api.core.pphtManage.getAccountDetail(this.id).then((res) => {
                if(res.code == 200) {
                    let _data = res.data
                    _data.id = _data.advertiserId
                    
                    Object.assign(this.form, _data)
                    this.dealerList = [{
                        id: _data.externalIdentityId,
                        name: _data.storeName
                    }]
                    let brandManufactorList = []

                    if(_data.advertiserType == 1) {
                        _data.brandManusList.map(item => {
                            brandManufactorList.push({
                                id: item.brandId,
                                brand_name: item.brandName
                            })
                        })
                    } else if(_data.advertiserType == 2) {
                        brandManufactorList = [
                            {
                                id: _data.principalId,
                                brand_name: _data.principalName
                            }
                        ]
                    }

                    this.form.brandManufactorList = brandManufactorList
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        onChangeType() {
            // this.dealerList = []
            this.form.externalIdentityId = undefined
            this.form.brandManufactorList = []
            if(this.form.advertiserType == 1) {
                this.onSearchDealer()
            }
        },
        onSearchDealer(val) {
            this.form.brandManufactorList = []

            let dealerParams = {
                search: val,
                state: 1, 
                page_size: 500
            }
            this.fetching = true
            this.$api.base_api.getDealerList(dealerParams).then(res => {
                let list = res.list || []
                this.dealerList = list
                this.fetching = false
            })
        },
        onChangeDealer(id) {
            if(id) {
                this.$api.base_api.getDealerDetail(id).then(res => {
                    this.form.brandManufactorList = res.brand_manufactor_list || []
                })
            } else {
                this.form.brandManufactorList = []
            }
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data || []
                } else {
                    this.$message.error(`获取主体列表失败，${res.message}`)
                }
            })
        },
        onChangePrincipal(val, option) {
            this.form.brandManufactorList = [
                {
                    id: val,
                    brand_name: option.data.attrs.name
                }
            ]
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/pphtManage/accountList'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    this.$api.core.pphtManage.submitAccountDetail( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/pphtManage/accountList'
                            })
                        } else {
                            this.$message.error('提交失败')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>